<template>
  <BContainer>
    <h2 class="fw-bold heading text-black mb-2" v-if="listing && listing.title">
      {{ "Toewijzen: " + listing.title }}
    </h2>
    <AssigningTable
      :showAction="true"
      @candidateAssign="candidateAssign"
      @rowSelect="onRowSelect"
    ></AssigningTable>
  </BContainer>
</template>

<script>
import AssigningTable from "@/components/Broker/AssigningTable/AssigningTable.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      selectedRowId: null,
    };
  },
  computed: {
    ...mapGetters("requestsAndAppointment", ["assignings", "listing"]),
    ...mapGetters("loadingError", ["getLoadings"]),
    listingTitle() {
      const propertyTypes = this.$t("singleListing.propertyType");
      const listingTitleArr = this.listing.title.split(" ");
      listingTitleArr[0] = propertyTypes[listingTitleArr[0].toLowerCase()];
      return listingTitleArr.join(" ");
    },
    getSelectedRowData() {
      if (!this.selectedRowId) return null;
      return this.assignings.find((req) => req.id === this.selectedRowId);
    },
  },
  components: {
    AssigningTable,
  },
  methods: {
    ...mapActions("requestsAndAppointment", ["assignCandidate", "validateAssigning"]),
    onRowSelect(rowId) {
      this.selectedRowId = rowId;
    },
    async candidateAssign(body) {
      await this.assignCandidate({ body, assigningId: this.selectedRowId });
      this.$bvModal.hide("assign-candidate-modal");
      this.$router.push({
        name: "ListingAssigned",
        params: { listingId: this.listing.id },
      });
    },
    async validate() {
      this.validateAssigning({
        id: this.selectedRowId,
        body: { validated: true },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.listing-assignment {
  .table-actions {
    .btn {
      flex-basis: 201px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__kyc {
    width: 20px;
    height: 20px;
  }
}
</style>
